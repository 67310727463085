import React, {useState, useEffect, useContext} from "react"

import "./style.scss"
import { inlineLabels } from "../ProductWrapper/mockedData"
import { getInlinePrescription, selectDefaultValue } from "../../services/utils"
import {cadenceOptionsValues, julyPriceTestClassic, quantityOptionsValues} from "../ContactsSummary/mockedData"
import { getValues } from "../../services/utils"
import WebpImage from "../WebpImage"
import CustomerContext from "../../context/customerContext"
import { usePromo, promoStart, promoEnd } from "../../utils/usePromo"
import NewCustomSelect from "../NewCustomSelect"
import { ParamBlock } from "../SummaryOrder"
import EditRxModal from "../EditRxModal"
import CartContext from "../../context/cartContext"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useIsLifemart from '../Lifemart/hooks/useIsLifemart'
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import useIsMilitary from "../Military/hooks/useIsMilitary"
import {hubbleClassic} from "../../services/data";
import {getHubblePricing} from "../../services/data";

const CheckoutItemsList = ({ items }) => {
  const { shippingPrice } = useContext(CustomerContext)
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const { removeItemCart } = useContext(CartContext)
  const hasContactsAvg = items?.some(e => e.type === "contacts" && e.isAvg)

  return (
    <div className="checkout-items-list">
      {React.Children.toArray(
        items.map(item => {
          const { type, isHydro, isSkyhy, prescription, lens_quantity } = item
          const filteredPrescriptionObj = prescription ? Object.keys(prescription).reduce((acc, key) => {
            if (Object.values(prescription[key]).length !== 0) {
              acc[key] = prescription[key]
            }
            return acc
          }, {}) : {}

          if (isNewRx || isNewRxDesktop) {
            if (type === "contacts")
              return <ContactsNewRxFlowItem {...item} filteredPrescriptionObj={filteredPrescriptionObj} isMilitary={isMilitary && lens_quantity === 30} shippingPrice={shippingPrice} isLifemart={isLifemart && lens_quantity === 30}/>

            if (type === "glasses" || type === "sunglasses" || type === "accessory")
              return <ProductNewRxFlowItem {...item} isLifemart={isLifemart} removeItem={() => removeItemCart(item.cart_item_id)} hasContactsAvg={hasContactsAvg}/>
          }

          if (type === "contacts" && !(isHydro || isSkyhy))
            return <ContactsClassicItem {...item} filteredPrescriptionObj={filteredPrescriptionObj} isMilitary={isMilitary && lens_quantity === 30} shippingPrice={shippingPrice} isLifemart={isLifemart && lens_quantity === 30}/>

          if (type === "contacts" && (isHydro || isSkyhy))
            return <ContactsHydroItem {...item} filteredPrescriptionObj={filteredPrescriptionObj} isMilitary={isMilitary && lens_quantity === 30} shippingPrice={shippingPrice} isLifemart={isLifemart && lens_quantity === 30}/>

          if (type === "glasses" || type === "sunglasses")
            return <ProductItem {...item} isLifemart={isLifemart}/>

          if (type === "accessory") {
            return <AccessoryItem {...item} />
          }

          return null
        })
      )}
    </div>
  )
}

const ProductNewRxFlowItem = ({
  image,
  title,
  price,
  colorTitle,
  lensType,
  type,
  brand,
  isLifemart,
  removeItem,
  isAvg
}) => {
  const glassesLensType = lensType?.value?.toLowerCase() === "standard" ? "Polycarbonate Lens" : lensType?.title
  const sunglassesLensType = lensType?.value?.toLowerCase() === "polarized" ? "Polarized Lens" : lensType?.title
  const framesLensType = type === "glasses" ? glassesLensType : sunglassesLensType
  const framesType = type === "glasses" ? 'Glasses' : (type === "sunglasses" ? "Sunglasses" : "")
  const productTitle = type === "accessory" ? title : `${title} ${framesType}`
  const isNewRx = useNewRxFlow();
  const isNewRxDesktop = useNewRxFlowDesktop();
  return (
    <div className="item-wrapper">
      <div className="top-content">
        <div className={`image-container ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
          <WebpImage objectFit="contain" fileName={image} />
        </div>
        <div className="text-container">
          <p className="title text h7">{productTitle}</p>
          <p className="price-info text h10 granite">{colorTitle}</p>
        </div>
        {isNewRxDesktop &&
          <a
            className="remove text h8 neutral"
            onClick={removeItem}
          >
            Remove
          </a>
        }
      </div>
      <div className="info-price-wrapper">
        {isNewRx &&
          <a
            className="remove text h8 neutral"
            onClick={removeItem}
          >
            Remove
          </a>
        }
        <p className="details">{type !== "accessory" ? framesLensType : brand}</p>
        <p className="price">${(isLifemart && type !== "accessory" && !isAvg) ? (price * 0.75).toFixed(2) : Number(price).toFixed(2)}</p>
      </div>
    </div>
  )
}

function transformTitle (title) {
  const index = title.match(/contact lenses/i)?.index;
  if (index > -1) {
    let transformed = title;
    transformed = title.slice(0, index) + "\n" + title.slice(index, title.length);
    return transformed;
  }

  return title;
}

const ContactsNewRxFlowItem = ({
  title,
  longTitle,
  cadence,
  lens_quantity,
  image,
  isOneEye,
  isHydro,
  isSkyhy,
  isAvg,
  price,
  sale_price,
  nonSubscription,
  filteredPrescriptionObj,
  description,
  cart_item_id,
  prescription,
  isMilitary,
  isLifemart,
  percent_off,
  is_duplicate_item,
  otpPurchase,
  value,
}) => {
  const { updateRx } = useContext(CartContext)
  const [showEditModal, setShowEditModal] = useState(false)
  const isPromo = usePromo()
  const isClassic = value === "classic"
  const contactsImage = (isHydro
    ? "Pages/ContactsPCP/Hubble-Hydro-Lenses.png"
    : isSkyhy
    ? "Pages/ContactsPCP/Hubble-Skyhy-Lenses.png"
    : isClassic
    ? "Pages/ContactsPCP/Hubble-Classic-Lenses.png"
    : image)
  const hubblePricing = getHubblePricing()
  const optionsValues = isHydro ? quantityOptionsValues(hubblePricing).hydro : (isSkyhy ? quantityOptionsValues(hubblePricing).skyhy : !isLifemart && !isMilitary && !nonSubscription ? [julyPriceTestClassic] : quantityOptionsValues(hubblePricing).classic)
  const militaryPrice = getValues(optionsValues, lens_quantity, "militaryPrice")
  const lifemartPrice = getValues(optionsValues, lens_quantity, "lifemartPrice")
  const regularPrice = otpPurchase ? price : !nonSubscription && getValues(optionsValues, lens_quantity, "oldPrice") || getValues(optionsValues, lens_quantity,"price")
  const recurringOrderPrice = value === "skyhy-monthly" ? price : isMilitary ? militaryPrice : isLifemart && lens_quantity === 30 ? lifemartPrice : regularPrice
  const isNewRxDesktop = useNewRxFlowDesktop();

  const monthDiscountedPrice =
    is_duplicate_item
    ? getValues(optionsValues, lens_quantity, "duplicatePrice")
    : getValues(optionsValues, lens_quantity, "oldPrice")
    ? getValues(optionsValues, lens_quantity, "price")
    : (isHydro ? hubblePricing.hydro.firstPrice : isSkyhy ? hubblePricing.skyhy.firstPrice : "1.00")
  const productTitle = `${transformTitle(longTitle || title)}${!nonSubscription ? " (Subscription)" : ""}`

  return (
    <div className="item-wrapper">
      <div className="top-content">
        <div className={`image-container ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
          <WebpImage objectFit="contain" fileName={contactsImage} />
        </div>
        <div className="text-container">
          <p className="title text h7">{productTitle}</p>
        </div>
      </div>

      <div className="prescription">
        <h5 className="title">Prescription</h5>
        {Object.entries(filteredPrescriptionObj)
          .sort()
          .reverse()
          .map(([eye, values], index) => {
            if (values.power === 'oneEye') return
            return (
              <p className={`eye-prescription text h10 ${isNewRxDesktop ? "new-rx-desktop" : "granite"}`} key={index}>{`${
                inlineLabels[eye]
              }: ${getInlinePrescription(values)}`}</p>
          )})}

        <button className="edit-modal-btn" onClick={() => setShowEditModal(true)}>Edit Your Prescription</button>
        <EditRxModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          price={(isAvg || nonSubscription || value === "skyhy-monthly") && price || monthDiscountedPrice}
          description={`${description?.split('. ')?.slice(0, -1)?.join(". ")}.`}
          isHydro={isHydro}
          isSkyhy={isSkyhy}
          cartItemId={cart_item_id}
          prescription={prescription}
          nonSubscription={nonSubscription}
          otpPurchase={otpPurchase}
        />

        <p className="prescription-disclaimer">
          Please make sure this information matches what’s on your prescription.
        </p>
      </div>

      {/* {!isAvg &&!nonSubscription &&
        <div className="cadence">
          <ParamBlock title="Subscription cadence" type="cadence">
            <p className="description text h7 neue">
              How often would you like a new {lens_quantity || 30} {isOneEye ? "lenses" : "pairs"}{" "}
              shipped to you?
            </p>
            <NewCustomSelect
              value={selectDefaultValue(cadenceOptionsValues.month, cadence)}
              onChange={({ value }) => updateRx('cadence', value, monthDiscountedPrice, cart_item_id)}
              options={cadenceOptionsValues.month}
              placeholder="ex. Every 4 Weeks"
            />
          </ParamBlock>
        </div>
      } */}

      <div className="info">
        {(isAvg || value === "skyhy-monthly") && !nonSubscription ?
          <>
            {sale_price ?
              <>
                <div className="old-price-wrapper">
                  <p className="text">{lens_quantity} {isOneEye ? "lenses" : "pairs"}</p>
                  <p className="old-price">
                    ${price}{!isOneEye ? "/eye" : ""}
                  </p>
                </div>
                <div className={`price-wrapper ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
                  {sale_price && <p className="text">Your first order</p>}
                  <p className="price">
                    ${sale_price}{!isOneEye ? "/eye" : ""}
                    {sale_price && <span className={`discount ${value === "skyhy-monthly" ? "blue" : ""}`}>{percent_off}% OFF</span>}
                  </p>
                </div>
                <p className={`recurring-disclaimer ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
                  {`After your first ${lens_quantity} ${!isOneEye ? "pairs" : "lenses"}, recurring\n orders will be $${price}${!isOneEye ? "/eye" : ""}.`}
                </p>
              </>
              :<>
                <div className={`price-wrapper ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
                  <p className="text">{title}</p>
                  <p className="price">
                    {price}
                  </p>
                </div>
              </>
            }

          </>
        : nonSubscription ?
          <>
            <div className={`price-wrapper ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
              <p className="text">
                <p className="text">{lens_quantity} {isOneEye ? "lenses" : "pairs"}</p>
              </p>
              <p className="price">
                {`$${recurringOrderPrice}${!isOneEye ? "/eye" : ""}`}
              </p>
            </div>
          </>
        :
          <>
            <div className="old-price-wrapper">
              <p className="text">{lens_quantity || 30} {isOneEye ? "lenses" : "pairs"}</p>
              <p className="old-price">
                ${recurringOrderPrice}{!isOneEye ? "/eye" : ""}
              </p>
            </div>
            <div className={`price-wrapper ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
              <p className="text">
                {isClassic && !is_duplicate_item ? `${hubbleClassic.trialPeriod}-day starter pack` : "Your first order"}
              </p>
              <p className="price">
                {isClassic && !is_duplicate_item ? "$1.00" : `$${monthDiscountedPrice}${!isOneEye ? "/eye" : ""}`}
                {!isClassic && <span className="discount">{percent_off || (isHydro ? hubblePricing.hydro.percentOff : hubblePricing.skyhy.percentOff)}% OFF</span>}
              </p>
            </div>
            <p className={`recurring-disclaimer ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
              {`${isClassic && !is_duplicate_item ? `After your ${hubbleClassic.trialPeriod}-day starter pack` : `After your first ${lens_quantity || 30} ${!isOneEye ? "pairs" : "lenses"}`}, recurring\n orders will be $${recurringOrderPrice}${!isOneEye ? "/eye" : ""}${isLifemart && lens_quantity === 30 ? ' (15% off)' : ''}.`}
            </p>
          </>
        }
      </div>
    </div>
  )
}

const ContactsHydroItem = ({
  title,
  cadence,
  lens_quantity,
  quantity,
  image,
  price,
  type,
  isOneEye,
  isHydro,
  isSkyhy,
  nonSubscription,
  filteredPrescriptionObj,
  isMilitary,
  shippingPrice,
  isLifemart
}) => {
  const hubblePricing = getHubblePricing()
  const isPromo = usePromo(promoStart, promoEnd)
  const optionsValues = isHydro ? quantityOptionsValues(hubblePricing).hydro : quantityOptionsValues(hubblePricing).skyhy
  const shipmentCadence = `${cadence} ${
    lens_quantity === 30 ? "weeks" : "months"
  } `
  const eyeMultiplier = !isOneEye ? 2 : 1
  const militaryPrice = getValues(optionsValues, lens_quantity, "militaryPrice")
  const lifemartPrice = getValues(optionsValues, lens_quantity, "lifemartPrice")
  const regularPrice = getValues(optionsValues, lens_quantity,"price")
  const recurringOrderPrice = isMilitary ? militaryPrice : isLifemart ? lifemartPrice : regularPrice

  let hydroPrice = "9.99/eye"
  let skyhyPrice = "17.99/eye"
  if (isPromo) {
    hydroPrice = "9.99/eye"
    skyhyPrice = "11.99/eye"
  }
  const monthDiscountedPrice = isHydro ? hydroPrice : skyhyPrice
  const totalPrice =
    getValues(optionsValues, lens_quantity, "price") *
    eyeMultiplier
  const product = isHydro ? "Hydro" : isSkyhy ? "Skyhy" : "Lenses"
  const productTitle = !nonSubscription ? `${title} Subscription` : `Single Purchase ${product} by Hubble`

  return (
    <CheckoutWrapper
      {...{ image, quantity, type, isOneEye, isHydro, isSkyhy,  nonSubscription }}
      title={productTitle}
    >
      <div className="starter-pack">
        <div className="price-container">
          <span className="text h10 neue-bold deep-blue">
            Your {!nonSubscription ? "first" : ""} {lens_quantity} {isOneEye ? "lenses" : "pairs"}:
          </span>
          <span className="text h7 neue-bold deep-blue">
            ${lens_quantity === 30 ? monthDiscountedPrice : totalPrice}{!nonSubscription && "*"}
          </span>
        </div>
        {!nonSubscription &&
          <p className="disclaimer text h10 granite">
            *recurring orders are ${recurringOrderPrice}{" "}
            {isOneEye ? "" : "per eye"} + ${shippingPrice} shipping, and shipped & billed every {shipmentCadence}
            (depending on doctor communication)
          </p>}
      </div>
      <div className="prescription">
        <p className="cadence text h10 deep-blue">
          {nonSubscription ? "" : "Every"} {cadence} {lens_quantity === 30 ? "Weeks" : "Months"} {nonSubscription ? "Supply" : ""}
        </p>
        {Object.entries(filteredPrescriptionObj)
          .sort()
          .reverse()
          .map(([eye, values], index) => (
            <p className="eye-prescription text h10 granite" key={index}>{`${
              inlineLabels[eye]
            }: ${getInlinePrescription(values)}`}</p>
          ))}
      </div>
    </CheckoutWrapper>
  )
}

const ContactsClassicItem = ({
  title,
  cadence,
  lens_quantity,
  prescription,
  quantity,
  image,
  price,
  type,
  sku,
  isOneEye,
  nonSubscription,
  filteredPrescriptionObj,
  isMilitary,
  shippingPrice,
  isLifemart
}) => {
  const hubblePricing = getHubblePricing()
  const productTitle = !nonSubscription ? `${title} Subscription` : 'Single Purchase Hubble Daily Contact Lenses'
  const militaryPrice = getValues(quantityOptionsValues(hubblePricing).classic, lens_quantity, "militaryPrice")
  const lifemartPrice = getValues(quantityOptionsValues(hubblePricing).classic, lens_quantity, "lifemartPrice")
  const regularPrice = getValues(quantityOptionsValues(hubblePricing).classic, lens_quantity,"price")
  const recurringOrderPrice = isMilitary ? militaryPrice : isLifemart ? lifemartPrice : regularPrice

  return (
    <CheckoutWrapper
      {...{ image, quantity, type, isOneEye, nonSubscription }}
      title={productTitle}
    >
      <div className="starter-pack">
        <div className="price-container">
          <span className="text h10 neue-bold deep-blue">
            {nonSubscription ? `Your ${lens_quantity} ${isOneEye ? "lenses" : "pairs"}` : `${hubbleClassic.trialPeriod}-day starter pack`}
          </span>
          <span className="text h7 neue-bold deep-blue">${price}{!nonSubscription && "*"}</span>
        </div>
        {!nonSubscription && <>
          {!isOneEye ? (
            <p className="disclaimer text h10 granite">
              *recurring orders are $
              {+recurringOrderPrice * 2}{" "}
              for both eyes + ${shippingPrice} shipping, and begin after {hubbleClassic.trialPeriod}-day starter pack
              (depending on doctor communication)
            </p>
          ) : ( <>
            <p className="disclaimer text h10 granite">
              *recurring orders are $
              {recurringOrderPrice}{" "}
              + ${shippingPrice} shipping, and begin after {hubbleClassic.trialPeriod}-day starter pack
              (depending on doctor communication)
            </p>
          </>
          )}
        </>}
      </div>
      <div className="prescription">
        <p className="cadence text h10 deep-blue">
          {nonSubscription ? "" : "Every"} {cadence} {lens_quantity === 30 ? "Weeks" : "Months"} {nonSubscription ? "Supply" : ""}
        </p>
        {Object.entries(filteredPrescriptionObj)
          .sort()
          .reverse()
          .map(([eye, values], index) => (
            <p className="eye-prescription text h10 granite" key={index}>{`${
              inlineLabels[eye]
            }: ${getInlinePrescription(values)}`}</p>
          ))}
      </div>
    </CheckoutWrapper>
  )
}

const ProductItem = ({
  id,
  image,
  title,
  colorTitle,
  price,
  quantity,
  type,
  isLifemart
}) => {
  const calculatedPrice = isLifemart ? (price * 0.75).toFixed(2) : price
  return (
    <CheckoutWrapper
      {...{ image, type, quantity }}
      price={calculatedPrice}
      title={`${title} ${type}`}
      type="product"
    >
      <p className="description text h10 deep-blue">
        Free Protective case and microfiber cleaning cloth included
      </p>
      <p className="price-info text h10 granite">{`${colorTitle}, Frame ($${calculatedPrice})`}</p>
    </CheckoutWrapper>
)}

const AccessoryItem = ({ image, title, price, quantity, type }) => (
  <CheckoutWrapper
    {...{ image, price, type, quantity, title }}
  ></CheckoutWrapper>
)

const CheckoutWrapper = ({
  type,
  image,
  title,
  price,
  quantity,
  children,
  isHydro,
  isSkyhy
}) => {
  const totalPrice = price * quantity
  const contactsImage = isHydro ? "Pages/ContactsPCP/Hubble-Hydro-Lenses.png" : (isSkyhy ? "Pages/ContactsPCP/Hubble-Skyhy-Lenses.png" : image)


  return (
    <div className="checkout-wrapper" data-type={type}>
      <div
        className="image-container"
      >
        <WebpImage objectFit="contain" fileName={type === "contacts" ? contactsImage : image} />
      </div>
      <div className="text-container">
        <div className="head-block">
          <span className="title text h7 deep-blue neue-bold">{title}</span>
          {price && (
            <span className="price text h7 deep-blue neue-bold">
              ${totalPrice.toFixed(2)}
            </span>
          )}
        </div>
        <div className="body-block">
          {children}
          <p className="quantity text h10 granite">Quantity: {quantity}</p>
        </div>
      </div>
    </div>
  )
}

export default CheckoutItemsList
